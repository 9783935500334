import { faCircleCheck, faCircleXmark } from "@fortawesome/pro-thin-svg-icons";
import { useTranslations } from "next-intl";

import { StateTemplate } from "./StateTemplate";

export const VerifyLinkExpiredState = () => {
  const t = useTranslations("components.VerifyLinkExpiredState");
  return (
    <StateTemplate
      description={t("description")}
      icon={faCircleXmark}
      iconColor="text-red-500 dark:text-red-300"
      title={t("title")}
    />
  );
};

export const VerifyLinkFailedState = () => {
  const t = useTranslations("components.VerifyLinkFailedState");
  return (
    <StateTemplate
      description={t("description")}
      icon={faCircleXmark}
      iconColor="text-red-500 dark:text-red-300"
      title={t("title")}
    />
  );
};

export const VerifyLinkSuccessState = () => {
  const t = useTranslations("components.VerifyLinkSuccessState");
  return (
    <StateTemplate
      description={t("description")}
      icon={faCircleCheck}
      iconColor="text-green-300"
      title={t("title")}
    />
  );
};
