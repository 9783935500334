"use client";

import {
  useAuth,
  useSignIn as useClerkSignIn,
  useSignUp as useClerkSignUp,
  useUser,
} from "@clerk/nextjs";
import { OAuthStrategy } from "@clerk/types";
import { useAnalytics } from "@everfund/event-detective";
import { useQueryClient } from "@tanstack/react-query";
import { createActorContext } from "@xstate/react";
import { Route } from "next";

import {
  addSegment,
  initialiseCrisp,
  ONBOARDING_SEGMENT_ORDERING,
} from "~/context/auth/crispUtils";
import { EVERFUND_DASHBOARD_URL } from "~/context/constants";
import { useRouter } from "~/navigation";
import useCreateUser from "~/requests/mutations/createUser";
import useSignedIn from "~/requests/mutations/userSignIn";
import useValidateLogin from "~/requests/mutations/validateLogin";
import routes from "~/utils/routes";
import { isTruthy } from "~/utils/shared";

import authMachine, { authMachineDefaultContext } from "./authMachine";
import {
  getOnboardingStepStatus,
  isOnboardingCompleted,
  OnboardingSteps,
} from "./utils";

export const AuthContext = createActorContext(authMachine, {
  devTools: false,
});

export type AuthMachineProviderProps = {
  children: JSX.Element;
  type?: "redirectFlow" | "signIn" | "signUp" | "tokenSignIn";
};

export const AuthMachineProvider = (props: AuthMachineProviderProps) => {
  const router = useRouter();
  const { track } = useAnalytics();

  const createUserMutation = useCreateUser();
  const validateLoginMutation = useValidateLogin();

  const queryClient = useQueryClient();
  const {
    isLoaded: signInLoaded,
    setActive,
    signIn: clerkSignIn,
  } = useClerkSignIn();
  const { isLoaded: signUpLoaded, signUp: clerkSignUp } = useClerkSignUp();
  const { isSignedIn, user } = useUser();
  const { mutate: signedIn } = useSignedIn();
  const { signOut } = useAuth();

  const redirectPayload = {
    isAuthenticated: isTruthy(isSignedIn),
    missingExternalId: !isTruthy(user?.externalId),
    missingOnboardingStatus:
      isSignedIn &&
      !isOnboardingCompleted(
        getOnboardingStepStatus(user) as Partial<OnboardingSteps>,
      ),
    missingTeam: false,
    type: props.type,
  };

  const formMachine = authMachine
    .withContext({
      ...authMachineDefaultContext,
      ...redirectPayload,
    })
    .withConfig({
      services: {
        authenticateWithEmail: async (context) => {
          const res = await clerkSignIn?.create({
            identifier: context.emailAddress!,
          });
          return res;
        },
        authenticateWithProvider: async (context, event) => {
          const strategy: OAuthStrategy = event.strategy;

          // Will work correctly for SignIn (i.e no redirect) but not SignUp (i.e redirect to clerk signUpPage)
          if (context.type === "signIn") {
            await clerkSignIn?.authenticateWithRedirect({
              continueSignUp: true,
              redirectUrl: "/authentication/oauth",
              redirectUrlComplete: "/authentication/redirect",
              strategy,
            });
          }
          if (context.type === "signUp") {
            // Will work correctly for SignUp (i.e no redirect) but not SignIn (i.e redirect to clerk signInPage)
            await clerkSignUp?.authenticateWithRedirect({
              continueSignUp: true,
              redirectUrl: "/authentication/oauth",
              redirectUrlComplete: "/authentication/redirect",
              strategy,
              unsafeMetadata: {},
            });
          }

          // We just want both to redirect to login/flow,
          // and in login flow we first check for clerk.externalId if it does not exisit we know we need to update
          // the clerk user with our custom metadatas, and create them a user in the everfund Database
          // ??? how do we do that ???
        },
        authenticateWithToken: async (context) => {
          track({
            name: "token-sign-in",
            properties: {},
          });

          const res = await clerkSignIn?.create({
            strategy: "ticket",
            ticket: context.signInToken!,
          });
          return res;
        },
        authenticateWithTokenError: async (context) => {
          return router.push("/sign-in" as unknown as Route);
        },
        // redirect flow actions
        buildAuthRedirect: async () => {
          return redirectPayload;
        },
        checkTokenVerification: async (_, e) => {
          const createdSessionId = e.data?.createdSessionId;
          if (!createdSessionId) {
            throw new Error("Session ID is not defined");
          }
          await setActive?.({
            session: createdSessionId,
          });

          signedIn({});
        },
        checkVerification: async (_, e) => {
          const verification = e.data.firstFactorVerification;
          if (
            verification.verifiedFromTheSameClient() ||
            verification.status === "verified"
          ) {
            const createdSessionId = e.data?.createdSessionId;
            await setActive?.({
              session: createdSessionId,
            });
            signedIn({});

            return "MAGIC_FLOW_VERIFIED";
          } else if (verification.status === "expired") {
            return "MAGIC_FLOW_EXPIRED";
          }
        },
        createSocialSignIn: async () => {
          // TODO: Rebuild OAuth
        },
        destroySession: async () => {
          await setActive?.({
            session: null,
          });
          localStorage.removeItem("REACT_QUERY_OFFLINE_CACHE");
          queryClient.clear();
          queryClient.invalidateQueries();
          await signOut();
        },
        redirectUser: async (_) => {
          if (redirectPayload.missingTeam) {
            return router.push(routes.onboarding);
          }

          // urlRedirectParams is the value of the redirectTo query param

          // urlRedirectParams is the value of the redirectTo query param
          const urlRedirectParams = new URLSearchParams(
            window.location.search,
          ).get("redirectTo");
          // localRedirectParams is the value of the redirectTo key in localStorage
          const localRedirectParams = window.localStorage.getItem("redirectTo");
          // if the urlRedirectParams and localRedirectParams are different, we want to
          const redirectToParams = localRedirectParams || urlRedirectParams;
          // if the user is authenticated, and they're onboarded,
          // and they're part of a team, and they have a role, and
          // they have a redirect param, redirect them to that param.
          if (
            redirectToParams &&
            localRedirectParams !== urlRedirectParams &&
            urlRedirectParams
          ) {
            window.localStorage.setItem("redirectTo", urlRedirectParams);
            // Cheating with the Route type here, as we could in theory redirect anywhere, not just a dashboard url
            return router.push(urlRedirectParams as Route);
          } else {
            router.push(routes.home);
          }
        },

        signInValidationFlow: async (context) => {
          const res = await validateLoginMutation.mutateAsync({
            email: context.emailAddress!.toLowerCase(),
          });

          if (!res.success) {
            throw new Error(res.message);
          }

          return res;
        },
        signUpFlow: async (context) => {
          initialiseCrisp({ clerkUser: user });
          addSegment("Signed Up", user, ONBOARDING_SEGMENT_ORDERING);

          return createUserMutation.mutateAsync({
            agreedToLegals: context.agreedToLegals!,
            emailAddress: context.emailAddress!.toLowerCase(),
            firstName: context.firstName!,
            lastName: context.lastName!,
          });
        },
        startMagicFlow: async (context) => {
          if (!isTruthy(context.emailAddressId)) {
            throw new Error("Email Address ID is not defined");
          }

          const { startEmailLinkFlow } = clerkSignIn?.createEmailLinkFlow()!;

          return await startEmailLinkFlow({
            emailAddressId: context.emailAddressId,
            redirectUrl: EVERFUND_DASHBOARD_URL + "/authentication/email-link",
          });
        },
      },
    });

  if (!signInLoaded || !signUpLoaded) {
    return null;
  }

  return (
    <AuthContext.Provider machine={() => formMachine}>
      {props.children}
    </AuthContext.Provider>
  );
};
