import { cn } from "@everfund/ui";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

import { Falsy, isTruthy } from "~/utils/shared";

const textSizeSm = `text-sm`;

export const formEditStyle = `sentry-mask relative bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10`;

export const formMultiElementEditStyle = `sentry-mask relative bg-gray-50 border border-gray-300 text-gray-900 text-sm focus-within:!ring-2 focus-within:!ring-blue-500 focus-within:!border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus-within:!ring-blue-500 dark:focus-within:!border-blue-500 focus:z-10`;

export const formStyle = `sentry-mask h-12 flex items-center bg-gray-100 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 cursor-not-allowed dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-500 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500`;

export const formOuterElementStyle = `flex flex-row items-center bg-gray-100 p-0 border border-gray-300 focus-within:!ring-blue-500 focus-within:!border-blue-500 w-full cursor-not-allowed dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-500 dark:focus-within:!ring-blue-500 dark:focus-within:!border-blue-500 justify-between`;

export const formInnerElementStyle = `flex-auto h-12 outline-none border-none bg-transparent border border-gray-300 text-gray-900 text-sm focus:!ring-0 focus:!border-0 focus:!border-none focus:!outline-0 focus:!outline-offset-0 focus:!shadow-none p-2.5 cursor-not-allowed dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-500 dark:text-gray-400 dark:focus:!border-none dark:focus:!outline-none`;

export const formAddonEditLeft = `inline-flex items-center px-3 rounded-l-md border border-r-0 sm:text-sm bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white`;

export const formAddonLeft = `inline-flex items-center px-3 rounded-l-md border border-r-0 sm:text-sm cursor-not-allowed text-gray-900 bg-gray-200 border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white`;

export const formLabelStyle = `mb-2 text-sm font-medium text-gray-900 dark:text-gray-300 flex justify-between items-center`;

export const formErrorMessage = cn(`text-red-500`, textSizeSm);

export const justErrorBorder = cn(`border-red-500 dark:border-red-400`);

export const formInputFocus = (error: boolean): string =>
  cn(
    "focus:ring-2",
    error
      ? "focus:ring-red-500 dark:focus:ring-red-400"
      : "focus:ring-blue-500 dark:focus:ring-blue-500",
  );

export const formOuterElemenerFocus = (error: boolean): string =>
  cn(
    "focus-within:!ring-2",
    error
      ? "focus-within:!ring-red-500 dark:focus-within:!ring-red-400"
      : "focus-within:!ring-blue-500 dark:focus-within:!ring-blue-500",
  );

export const formInputBorder = (error: boolean): string =>
  error
    ? cn(justErrorBorder, "dark:focus:border-red-400")
    : "border-gray-300 dark:border-gray-600";

export const formOuterElementBorder = (error: boolean): string =>
  error
    ? cn(justErrorBorder, "dark:focus-within:!border-red-400")
    : "border-gray-300 dark:border-gray-600";

export type FieldErrorType =
  | FieldError
  | Merge<FieldError, FieldErrorsImpl<any>>
  | Partial<{
      message: string;
      type: number | string;
    }>;

export const inputClassName = <T,>(
  error: Falsy | T,
  extraClassNames?: Array<null | string | undefined> | string,
) =>
  cn(
    formEditStyle,
    formInputFocus(isTruthy(error)),
    formInputBorder(isTruthy(error)),
    extraClassNames,
  );

export const multiElementInputClassName = <T,>(
  error: Falsy | T,
  extraClassNames?: string | string[],
) =>
  cn(
    formMultiElementEditStyle,
    formOuterElemenerFocus(isTruthy(error)),
    formOuterElementBorder(isTruthy(error)),
    extraClassNames,
  );

export const inputCardClassName = <T,>(
  error: Falsy | T,
  extraClassNames?: string | string[],
) =>
  cn(
    "rounded-md border",
    isTruthy(error) ? justErrorBorder : "dark:border-nord-0 border-gray-300",
    extraClassNames,
  );
