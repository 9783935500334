"use client";

import { useTranslations } from "next-intl";
import { HTMLInputTypeAttribute, useEffect } from "react";
import {
  FieldErrors,
  FieldValues,
  Resolver,
  SubmitHandler,
  useForm,
  UseFormRegisterReturn,
  UseFormReturn,
} from "react-hook-form";

import { AuthContext } from "~/context/auth/AuthMachineProvider";
// } from '@fortawesome/free-brands-svg-icons';
import { Link } from "~/navigation";
import Button from "~/shared/Button";
import { inputClassName } from "~/shared/FormInputs/FormStyles";

// import { OAuthStrategy } from '@clerk/types';
// import {
//   IconDefinition,
//   faGithub,
//   faGoogle,
//   faLinkedinIn,
//   faMicrosoft,
import { cn, Logo } from "@everfund/ui";

// type SocialSignInItem = {
//   title: string;
//   icon: IconDefinition;
//   strategy: OAuthStrategy;
// };

type FormProps<TFormValues extends FieldValues> = {
  children: (methods: UseFormReturn<TFormValues>) => React.ReactNode;
  onSubmit: SubmitHandler<TFormValues>;
  resolver: Resolver<TFormValues>;
};

const AuthForm = <TFormValues extends FieldValues>({
  children,
  onSubmit,
  resolver,
}: FormProps<TFormValues>) => {
  const formContext = useForm<TFormValues>({ resolver });
  const stateValue = AuthContext.useSelector((state) => state.value);
  const stateFlowType = AuthContext.useSelector((state) => state.context.type);
  const stateErrors = AuthContext.useSelector(
    (state) => state.context.errors,
  ) as Record<string, string>;
  const t = useTranslations("components.AuthForm");

  // const socialSignIn: SocialSignInItem[] = [
  //   {
  //     title: t('authForm.socialSignIn.google'),
  //     icon: faGoogle,
  //     strategy: 'oauth_google',
  //   },
  //   {
  //     title: t('authForm.socialSignIn.linkedIn'),
  //     icon: faLinkedinIn,
  //     strategy: 'oauth_linkedin',
  //   },
  //   {
  //     title: t('authForm.socialSignIn.gitHub'),
  //     icon: faGithub,
  //     strategy: 'oauth_github',
  //   },
  //   {
  //     title: t('authForm.socialSignIn.microsoft'),
  //     icon: faMicrosoft,
  //     strategy: 'oauth_microsoft',
  //   },
  // ];

  const isLoading =
    stateValue === "authenticateWithEmail" || stateValue === "startMagicFlow";

  useEffect(() => {
    if (stateErrors) {
      // go through fields and error them if needed
      Object.entries(stateErrors).map(([key, value]: [any, string]) => {
        formContext.setError(key, { message: value, type: "custom" });
      });
    }
  }, [formContext, stateErrors]);

  return (
    <>
      <Logo
        className={cn(
          "mx-auto mb-4 h-auto max-h-10 w-auto",
          stateFlowType === "signUp" && "md:hidden",
        )}
        textClassName="text-strength dark:text-white"
      />
      <form className="space-y-6" onSubmit={formContext.handleSubmit(onSubmit)}>
        <div>
          <h2 className="text-center text-2xl text-primary">
            {stateFlowType === "signUp" ? t("getStarted") : t("signInTo")}
          </h2>
        </div>
        <div className="space-y-4">{children(formContext)}</div>

        <Button
          buttonColor="blue"
          isFullWidth
          isLoading={isLoading}
          type="submit"
        >
          {stateFlowType === "signUp" ? t("signUp") : t("signIn")}
        </Button>

        <Link
          className="pl-px pt-4 text-sm text-primary"
          href={{
            pathname: stateFlowType === "signUp" ? "/sign-in" : "/sign-up",
          }}
        >
          {stateFlowType === "signUp" ? t("alreadyAUser") : t("newToEverfund")}
          <span className="text-blue-600 underline-offset-1 ease-in-out hover:text-blue-500 hover:underline">
            {stateFlowType === "signUp" ? t("signIn") : t("signUp")}
          </span>
        </Link>
      </form>
      {/* <div className="mt-6">
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="bg-white px-2 text-secondary dark:bg-nord-3">
              Or continue with
            </span>
          </div>
        </div>

        <div className="mt-6 grid grid-cols-4 gap-3">
          {socialSignIn.map(({ strategy, title, icon }) => (
            <button
              key={title}
              onClick={() => {
                send({ type: 'AUTH_WITH_PROVIDER', strategy })
              }}
              className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 dark:border-nord-1 dark:bg-nord-2 dark:text-white dark:hover:bg-nord-1"
            >
              <span className="sr-only">Sign in with {title}</span>
              <FontAwesomeIcon icon={icon} className="h-5 w-5" />
            </button>
          ))}
        </div>
      </div> */}
    </>
  );
};

type AuthTextFieldProps<
  TFieldValues extends FieldValues,
  TFieldName extends string,
> = {
  errors: FieldErrors<TFieldValues>;
  inputType: HTMLInputTypeAttribute;
  label: string;
  register: UseFormRegisterReturn<TFieldName>;
};

export const AuthTextField = <
  TFormValues extends FieldValues,
  TFieldName extends string,
>({
  errors,
  inputType,
  label,
  register,
}: AuthTextFieldProps<TFormValues, TFieldName>) => {
  return (
    <fieldset>
      <label
        className="block text-sm font-medium text-secondary"
        htmlFor={register.name}
      >
        {label}
      </label>
      <div>
        <input
          {...register}
          autoComplete={register.name}
          className={inputClassName(
            errors[register.name],
            `sentry-mask relative mt-1 flex rounded-lg text-sm`,
          )}
          type={inputType}
        />
      </div>
      {errors[register.name] && (
        <p className="mt-2 text-sm text-red-600 dark:text-red-400">
          {errors[register.name]?.message as string}
        </p>
      )}
    </fieldset>
  );
};

export default AuthForm;
