import { cn } from "@everfund/ui";
import { IconDefinition } from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lottie from "lottie-react";

import { AuthContext } from "~/context/auth/AuthMachineProvider";
import Button from "~/shared/Button";

type StateTemplateIconRequiredProps = {
  icon?: IconDefinition;
  iconColor?: string;
};

type StateTemplateAnimationRequiredProps = {
  animation?: any;
};

export interface StateTemplateProps
  extends StateTemplateIconRequiredProps,
    StateTemplateAnimationRequiredProps {
  description?: React.ReactNode;
  resetButton?: boolean;
  title?: null | string;
}

const ButtonWithXState = () => {
  const [, send] = AuthContext.useActor();
  return (
    <Button buttonColor="blue" isFullWidth onClick={() => send("RESET")}>
      Try Again
    </Button>
  );
};
export const StateTemplate = (props: StateTemplateProps) => {
  return (
    <>
      <div className="space-y-3">
        {props?.icon && (
          <div className="mb-4 flex items-center">
            <FontAwesomeIcon
              className={cn("mx-auto h-32 w-32 p-3", props.iconColor)}
              icon={props.icon}
            />
          </div>
        )}
        {props?.animation && (
          <div className="mx-auto w-auto">
            <Lottie
              animationData={props?.animation}
              loop={0}
              style={{
                borderRadius: "100%",
                height: 144,
                margin: "0 auto",
                overflow: "hidden",
                width: 144,
              }}
            />
          </div>
        )}
        <div>
          {props?.title && (
            <h2 className="pb-4 text-center text-xl font-medium text-primary">
              {props.title}
            </h2>
          )}
          {props.description && (
            <p className="mb-6 text-center text-base text-secondary">
              {props.description}
            </p>
          )}

          {props.resetButton && <ButtonWithXState />}
        </div>
      </div>
    </>
  );
};
