import { createElement } from "react";

import CheckEmailState from "~/layouts/Unauthenticated/views/CheckEmailState";
import {
  VerifyLinkExpiredState,
  VerifyLinkFailedState,
  VerifyLinkSuccessState,
} from "~/layouts/Unauthenticated/views/LinkStatusState";
import {
  LoadingState,
  LoadingStateFullScreen,
} from "~/layouts/Unauthenticated/views/LoadingState";
import MagicFlowExpiredState from "~/layouts/Unauthenticated/views/MagicFlowExpiredState";
import NetworErrorState from "~/layouts/Unauthenticated/views/NetworErrorState";
import * as RedirectStates from "~/layouts/Unauthenticated/views/RedirectStates";
import SignIn from "~/layouts/Unauthenticated/views/SignIn";
import SignUp from "~/layouts/Unauthenticated/views/SignUp";

import TokenSignIn from "./views/TokenSignIn";

export {
  CheckEmailState,
  LoadingState,
  LoadingStateFullScreen,
  MagicFlowExpiredState,
  NetworErrorState,
  RedirectStates,
  SignIn,
  SignUp,
  VerifyLinkFailedState,
  VerifyLinkSuccessState,
};

export const AuthenticateViews: Record<string, () => JSX.Element | null> = {
  authFlow_magicFlowExpired: MagicFlowExpiredState,
  //authFlow
  authFlow_signIn: SignIn,
  authFlow_signUp: SignUp,
  authFlow_startMagicFlow: CheckEmailState,
  authFlow_tokenSignIn: TokenSignIn,

  redirect_authenticatedOnStart:
    RedirectStates.RedirectToRedirectFlow as () => never,
  // Redirect States for authFlow
  redirect_onboarding: RedirectStates.OnboardingState as () => never,
  redirect_unAuthenticated: RedirectStates.UnAuthenticatedState as () => never,

  // multiple Pages
  utils_loadingState: LoadingState,
  utils_loadingState__fullscreen: LoadingStateFullScreen,
  utils_networkError: NetworErrorState,

  verification_StatusExpired: VerifyLinkExpiredState,
  verification_StatusFailed: VerifyLinkFailedState,
  //tabVerification
  verification_StatusSuccess: VerifyLinkSuccessState,
};

interface RenderAuthProps {
  authState: string;
}

const RenderAuth = ({ authState }: RenderAuthProps) => {
  // authState does exist
  if (typeof AuthenticateViews[authState] !== "undefined") {
    return createElement(AuthenticateViews[authState], {
      block: authState,
      key: authState,
    });
  } else {
    return createElement(AuthenticateViews["idle"], {
      block: "utils_networkError",
      key: "utils_networkError",
    });
  }
};

export default RenderAuth;
