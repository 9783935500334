import { useMutation } from "@tanstack/react-query";

import { ValidateLoginResponse } from "~/services/users/validateLogin";
import { ValidateLoginInput } from "~/services/users/validateLogin";

const useValidateLogin = () => {
  return useMutation<ValidateLoginResponse, unknown, ValidateLoginInput>({
    mutationFn: async (variables) =>
      (
        await fetch(
          `/api/users/validateLogin?email=${encodeURIComponent(variables.email)}`,
        )
      ).json(),
    mutationKey: ["validateLogin"],
  });
};

export default useValidateLogin;
