import { faCircleXmark } from "@fortawesome/pro-thin-svg-icons";
import { useTranslations } from "next-intl";

import { StateTemplate } from "./StateTemplate";

const MagicFlowExpiredState = () => {
  const t = useTranslations("components.MagicFlowExpiredState");
  return (
    <StateTemplate
      description={t("description")}
      icon={faCircleXmark}
      iconColor="text-red-500 dark:text-red-300"
      resetButton
      title={t("title")}
    />
  );
};

export default MagicFlowExpiredState;
