import { useMutation } from "@tanstack/react-query";

import { CreateUserInput, CreateUserResponse } from "~/services/users/create";

const useCreateUser = () => {
  return useMutation<CreateUserResponse, unknown, CreateUserInput>({
    mutationFn: async (variables) =>
      (
        await fetch("/api/users/createUser", {
          body: JSON.stringify(variables),
          method: "POST",
        })
      ).json(),
    mutationKey: ["createUser"],
  });
};

export default useCreateUser;
