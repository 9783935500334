import { useTranslations } from "next-intl";

import { AuthContext } from "~/context/auth/AuthMachineProvider";

import mailAnimation from "./CheckEmailState.animation.json";
import { StateTemplate } from "./StateTemplate";

const CheckEmailState = () => {
  const stateFlowType = AuthContext.useSelector((state) => state.context.type);
  const stateEmailAddress = AuthContext.useSelector(
    (state) => state.context.emailAddress,
  );
  const t = useTranslations("components.CheckEmailState");

  const linkType = stateFlowType === "signIn" ? "sign up" : "sign in";

  return (
    <StateTemplate
      animation={mailAnimation}
      description={
        <p>
          {t.rich("message", {
            email: (chunk) => (
              <span className="font-semibold text-ever-500">{chunk}</span>
            ),
            linkType,
            stateEmailAddress,
          })}
        </p>
      }
      title="Check your email"
    />
  );
};

export default CheckEmailState;
