import { cn, Logo } from "@everfund/ui";
import { useSearchParams } from "next/navigation";
import { useTranslations } from "next-intl";
import { useEffect } from "react";

import { AuthContext } from "~/context/auth/AuthMachineProvider";

export type SignInInputs = {
  emailAddress: string;
};

const TokenSignIn = () => {
  const [, send] = AuthContext.useActor();
  const signInToken = useSearchParams().get("token");
  const t = useTranslations("components.TokenSignIn");

  useEffect(() => {
    send({ data: { signInToken }, type: "SIGNIN_WITH_TOKEN" });
  }, [send, signInToken]);

  return (
    <>
      <Logo
        className={cn("mx-auto mb-4 h-auto max-h-10 w-auto")}
        textClassName="text-strength dark:text-white"
      />
      <h2 className="text-center text-2xl text-primary">{t("message")}</h2>
    </>
  );
};

export default TokenSignIn;
