import { faWarning } from "@fortawesome/pro-thin-svg-icons";
import { useTranslations } from "next-intl";

import { StateTemplate } from "./StateTemplate";

const NetworErrorState = () => {
  const t = useTranslations("components.NetworErrorState");
  return (
    <StateTemplate
      description={t("description")}
      icon={faWarning}
      iconColor="text-secondary"
      resetButton
      title={t("title")}
    />
  );
};

export default NetworErrorState;
