import { useAnalytics } from "@everfund/event-detective";
import { cn } from "@everfund/ui";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslations } from "next-intl";
import { SubmitHandler } from "react-hook-form";
import { z } from "zod";

import { AuthContext } from "~/context/auth/AuthMachineProvider";
import { EVERFUND_MARKETING_DOMAIN } from "~/context/constants";

import AuthForm, { AuthTextField } from "./AuthForm";
import { SignInInputs } from "./SignIn";

export type SignUpInputs = {
  agreedToLegals: boolean;
  firstName: string;
  lastName: string;
} & SignInInputs;

const SignUp = () => {
  const { track } = useAnalytics();
  const [, send] = AuthContext.useActor();
  const onSubmit: SubmitHandler<SignUpInputs> = (data) => {
    track({
      name: "clicked-sign-up",
      properties: {},
    });

    send({ data, type: "SIGNUP_WITH_EMAIL" });
  };
  const t = useTranslations("components.SignUp");

  const resolver = zodResolver(
    z.object({
      agreedToLegals: z.literal(true, {
        required_error: t("validation.legal"),
      }),
      emailAddress: z
        .string({
          required_error: t("validation.email"),
        })
        .email({ message: t("validation.emailInvalid") }),
      firstName: z
        .string({
          required_error: t("validation.firstName"),
        })
        .min(2, { message: t("validation.firstNameInvalid") }),
      lastName: z
        .string({
          required_error: t("validation.lastName"),
        })
        .min(2, { message: t("validation.lastNameInvalid") }),
    }),
  );

  return (
    <>
      <AuthForm<SignUpInputs> onSubmit={onSubmit} resolver={resolver}>
        {({ formState: { errors }, register }) => {
          return (
            <>
              <AuthTextField<SignInInputs, "emailAddress">
                errors={errors}
                inputType="email"
                label={t("fields.email")}
                register={register("emailAddress")}
              />

              <div className="flex flex-row space-x-4">
                <AuthTextField<SignInInputs, "firstName">
                  errors={errors}
                  inputType="text"
                  label={t("fields.firstName")}
                  register={register("firstName")}
                />
                <AuthTextField<SignInInputs, "lastName">
                  errors={errors}
                  inputType="text"
                  label={t("fields.lastName")}
                  register={register("lastName")}
                />
              </div>
              <fieldset className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    {...register("agreedToLegals")}
                    className={cn(
                      "sentry-mask dark:ring-offset-nord-3 h-4 w-4 rounded border-2 border-gray-300 text-blue-600 checked:border-blue-600 focus:ring-blue-500 dark:border-gray-700 dark:bg-gray-700 dark:checked:border-blue-600 dark:checked:bg-blue-600",
                      errors["agreedToLegals"] && "!border-red-500",
                    )}
                    name="agreedToLegals"
                    type="checkbox"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    className={cn(
                      "text-secondary",
                      errors["agreedToLegals"] &&
                        "text-red-500 dark:text-red-400",
                    )}
                    htmlFor="agreedToLegals"
                  >
                    I have read and agreed to the{" "}
                    <a
                      className="text-blue-600 hover:text-blue-500"
                      href={`https://${EVERFUND_MARKETING_DOMAIN}/legal/privacy-policy`}
                    >
                      Privacy Policy
                    </a>{" "}
                    and{" "}
                    <a
                      className="text-blue-600 hover:text-blue-500"
                      href={`https://${EVERFUND_MARKETING_DOMAIN}/legal/terms-and-conditions`}
                    >
                      Terms & Conditions
                    </a>
                    .
                  </label>
                </div>
              </fieldset>
            </>
          );
        }}
      </AuthForm>
    </>
  );
};

export default SignUp;
