import { UserResource } from "@clerk/types";
import dayjs from "dayjs";

import {
  LATEST_PRIVACY_POLICY,
  LATEST_TERMS_AND_CONDITIONS,
} from "../constants";
import { useUser } from "../user";

export type ViewStatus = "completed" | "notRequired" | "required";

const onboardingsteps = {
  agreePrivacy: "notRequired",
  agreeTAndC: "notRequired",
  askTeamName: "notRequired",
  askUsersName: "notRequired",
  getCodeSnippet: "required",
  pickUserType: "notRequired",
  showDonations: "required",
} as const;

export type OnboardingSteps = Record<keyof typeof onboardingsteps, ViewStatus>;

function setStepRequiredWhen(cond: boolean) {
  return cond ? "required" : "notRequired";
}

export function getOnboardingStepStatus(currentUser: UserResource) {
  const { user } = useUser();

  return {
    agreePrivacy: setStepRequiredWhen(
      !user?.agreedToPrivacy ||
        dayjs(user.agreedToPrivacy).isBefore(LATEST_PRIVACY_POLICY),
    ),
    agreeTAndC: setStepRequiredWhen(
      !user?.agreedToTerms ||
        dayjs(user?.agreedToTerms).isBefore(LATEST_TERMS_AND_CONDITIONS),
    ),
  };
}

export function isOnboardingCompleted(steps: Partial<OnboardingSteps>) {
  return (
    Object.values(steps).filter((step) => step === "required").length === 0
  );
}
