import { useAnalytics } from "@everfund/event-detective";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslations } from "next-intl";
import { SubmitHandler } from "react-hook-form";
import { z } from "zod";

import { AuthContext } from "~/context/auth/AuthMachineProvider";

import AuthForm, { AuthTextField } from "./AuthForm";

export type SignInInputs = {
  emailAddress: string;
};

const SignIn = () => {
  const [, send] = AuthContext.useActor();
  const { track } = useAnalytics();
  const onSubmit: SubmitHandler<SignInInputs> = (data) => {
    track({
      name: "clicked-sign-in",
      properties: {},
    });
    send({ data, type: "SIGNIN_WITH_EMAIL" });
  };
  const t = useTranslations("components.SignIn");

  const resolver = zodResolver(
    z.object({
      emailAddress: z
        .string({
          required_error: t("validation.email"),
        })
        .email({ message: t("validation.emailInvalid") }),
    }),
  );

  return (
    <AuthForm<SignInInputs> onSubmit={onSubmit} resolver={resolver}>
      {({ formState: { errors }, register }) => {
        return (
          <>
            <AuthTextField<SignInInputs, "emailAddress">
              errors={errors}
              inputType="email"
              label={t("label")}
              register={register("emailAddress")}
            />
          </>
        );
      }}
    </AuthForm>
  );
};

export default SignIn;
