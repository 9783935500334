import dayjs from "dayjs";

import { config, env } from "~/env";

// used on footers etc for marketing back to main marketing website
export const EVERFUND_MARKETING_DOMAIN = "everfund.com";
export const EVERFUND_DASHBOARD_URL =
  env.NEXT_PUBLIC_APPLICATION_ENVIRONMENT === "development"
    ? "http://localhost:4001"
    : "https://dashboard.everfund.com";

export const DEFAULT_DESCRIPTION =
  "Create, share and track high performance donation links and raise more from donors no matter where they are";

export const LATEST_TERMS_AND_CONDITIONS = dayjs(new Date(2021, 4, 17));
export const LATEST_PRIVACY_POLICY = dayjs(new Date(2021, 4, 17));
